<template>
  <navigation />
  <router-view />
  <footerVue />
  <div class="enlace_chat div-none-movil">
    <a
      id="wasap"
      href="https://api.whatsapp.com/send?phone=573228463663"
      target="_blank"
      ><img
        class="enlace_chat_wasap"
        src="./assets/PNGs/Whatsapp.png"
        alt="whasap"
    /></a>
    <img
      @click="chatVisibility()"
      src="./assets/PNGs/Group1778.png"
      alt="Chat"
    />
    <span v-if="token_countMessage_total !== 0" class="spanCountChatAdviser">{{
      token_countMessage_total
    }}</span>
  </div>
  <div class="button-top display-none-desk">
    <img @click="topMax()" src="./assets/PNGs/Vector_11.png" alt="" />
  </div>
  <Chat id="chat-oculto" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
export default {
  name: "App",
  components: {
    navigation: defineAsyncComponent(() =>
      import(/* webpackChunkName: "navigation" */ "@/navigation/navigation")
    ),
    footerVue: defineAsyncComponent(() =>
      import(/* webpackChunkName: "footerVue" */ "./modules/footer/footer.vue")
    ),
    Chat: defineAsyncComponent(() =>
      import(/* webpackChunkName: "chat" */ "./modules/chat/chat.vue")
    ),
  },
  data() {
    return {
      token_countMessage_total: 0,
    };
  },
  computed: {
    ...mapState(["chats"]),
  },

  mounted() {
    var lastScrollPosition = 0;
    var threshold = 200;
    window.addEventListener("scroll", function () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var scrollDistance = scrollTop - lastScrollPosition;
      const elemento = document.querySelector("#wasap");
      if (Math.abs(scrollDistance) >= threshold) {
        if (document.querySelector(".wasap_hover")) {
          elemento.classList.remove("wasap_hover");
        }
        lastScrollPosition = scrollTop;
      }
    });

    if (window.innerWidth >= 1200) {
      const elementoHover = document.querySelector(".enlace_chat");
      const elemento = document.querySelector("#wasap");

      elementoHover.addEventListener("mouseover", function () {
        elemento.classList.add("wasap_hover");
      });
      elementoHover.addEventListener("mouseout", function () {
        elemento.classList.remove("wasap_hover");
      });
    }
  },
  methods: {
    ...mapActions(["loadChat"]),
    chatVisibility() {
      if (window.innerWidth <= 1200) {
        const elemento = document.querySelector("#wasap");

        if (!document.querySelector(".wasap_hover")) {
          elemento.classList.add("wasap_hover");
        } else {
          elemento.classList.remove("wasap_hover");
          const element = document.querySelector("#chat-oculto");
          if (document.querySelector(".chat-visible")) {
            element.classList.remove("chat-visible");
          } else {
            element.classList.add("chat-visible");
          }
          let token_countMessage_1 = parseInt(
            localStorage.getItem("token_countMessage_1")
          );
          localStorage.setItem("token_countMessage_2", token_countMessage_1);
          localStorage.setItem("token_countMessage_total", 0);
          this.token_countMessage_total = parseInt(
            localStorage.getItem("token_countMessage_total")
          );
        }
      } else {
        const element = document.querySelector("#chat-oculto");
        if (document.querySelector(".chat-visible")) {
          element.classList.remove("chat-visible");
        } else {
          element.classList.add("chat-visible");
        }
        let token_countMessage_1 = parseInt(
          localStorage.getItem("token_countMessage_1")
        );
        localStorage.setItem("token_countMessage_2", token_countMessage_1);
        localStorage.setItem("token_countMessage_total", 0);
        this.token_countMessage_total = parseInt(
          localStorage.getItem("token_countMessage_total")
        );
      }
    },
    getRandomId() {
      const timestamp = Date.now().toString();
      const uniqueId = timestamp;
      return uniqueId;
    },
    countChatAdviser_1() {
      setTimeout(() => {
        let countMessage = 0;
        const values = Object.values(this.chats);
        for (let i = values.length - 1; i > 0; i--) {
          const chatObj = values[i];
          if (chatObj.typeUser === "adviser") {
            countMessage++;
          } else {
            break;
          }
        }
        localStorage.setItem("token_countMessage_1", countMessage);
      }, 500);
    },

    topMax() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    countChatAdviser_2() {
      if (!localStorage.getItem("token_countMessage_2")) {
        localStorage.setItem("token_countMessage_2", 0);
      }
    },

    countChatAdviserTotal() {
      setTimeout(() => {
        if (!document.querySelector(".chat-visible")) {
          let token_countMessage_1 = parseInt(
            localStorage.getItem("token_countMessage_1")
          );
          let token_countMessage_2 = parseInt(
            localStorage.getItem("token_countMessage_2")
          );
          let token_countMessage_total =
            token_countMessage_1 - token_countMessage_2;
          localStorage.setItem(
            "token_countMessage_total",
            token_countMessage_total
          );
          this.token_countMessage_total = parseInt(
            localStorage.getItem("token_countMessage_total")
          );
        } else {
          let token_countMessage_1 = parseInt(
            localStorage.getItem("token_countMessage_1")
          );
          localStorage.setItem("token_countMessage_2", token_countMessage_1);
        }
      }, 600);
    },

    load() {
      const firebaseConfig = {
        apiKey: "AIzaSyD8Ghlq7sioz4TRmstCXVltpcnfkRiX8UE",
        authDomain: "wimportaciones-b7da1.firebaseapp.com",
        databaseURL: "https://wimportaciones-b7da1-default-rtdb.firebaseio.com",
        projectId: "wimportaciones-b7da1",
        storageBucket: "wimportaciones-b7da1.appspot.com",
        messagingSenderId: "964574639089",
        appId: "1:964574639089:web:48f040e9899b8dbcdb4a50",
        measurementId: "G-JF7XP4SMVB",
      };
      const app = initializeApp(firebaseConfig);
      const database = getDatabase();
      const dataRef = ref(database, "chat");
      onValue(dataRef, (snapshot) => {
        this.loadChat();
        this.countChatAdviser_1();
        this.countChatAdviserTotal();
      });
    },
  },

  created() {
    this.load();
    this.countChatAdviser_2();
    if (!localStorage.getItem("token_chat")) {
      localStorage.setItem("token_chat", this.getRandomId());
    }
  },
};
</script>

<style></style>
