import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [

    {
        path: '/',
        redirect: '/home'
    },

    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '@/modules/home/home'),
    },

    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "About" */ '@/modules/about/About'),
        children: [
            {
                path: 'TreatmentPolicy',
                name: 'TreatmentPolicy',
                component: () => import(/* webpackChunkName: "TreatmentPolicy" */ '@/modules/about/sub-navigation/TreatmentPolicy/Treatmentpolicy'),
                
            },
            {
                path: 'aboutUs',
                name: 'aboutUs',
                component: () => import(/* webpackChunkName: "aboutUs" */ '@/modules/about/sub-navigation/aboutUs/aboutUs'),
                
            },
            {
                path: 'GuaranteePolicy',
                name: 'GuaranteePolicy',
                component: () => import(/* webpackChunkName: "GuaranteePolicy" */ '@/modules/about/sub-navigation/GuaranteePolicy/GuaranteePolicy'),
                
            },
            {
                path: 'environmentalPolicy',
                name: 'environmentalPolicy',
                component: () => import(/* webpackChunkName: "environmentalPolicy" */ '@/modules/about/sub-navigation/environmentalPolicy/environmentalpolicy'),
                
            },
        ]
    },

    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "Contact" */ '@/modules/contact/Contact'),
    },

    {
        path: '/catalogue',
        name: 'catalogue',
        component: () => import(/* webpackChunkName: "catalogue" */ '@/modules/Catalogue/Catalogue'),
    },

   

    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "NoPageFound" */ '@/modules/home/home')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    next()
  })

export default router