export default {
  es: {
    //navigation
    home: 'Inicio',
    about: 'Nosotros',
    contact: 'Contacto',
    Catalogue: ' Catálogo',

    //home
    our_brands: 'Nuestras marcas',
    you_want: '¿Quieres vender',
    National_entrepreneurs: 'Empresarios nacionales / Latam',
    our_products: 'nuestros productos',
    in_your_warehouse: 'en tu almacén?',
    want_export: '¿Quieres exportar tus productos a',
    colombia_latan: 'Colombia y Latam?',
    our_catalog: 'Revisa nuestro',
    product_catalog: 'Catálogo de productos',
    button_catalog: 'Ver catálogo',
    asian_businessmen: 'Empresarios asiáticos',
    Some_products: 'Algunos productos',
    Spare_motorcycles: 'Repuestos para motos de',
    We_offer_you: 'Te ofrecemos',
    Guarantee: 'Garantia',
    Financing: 'Financiación',
    Know_more: 'Saber más',
    say_us: 'Lo que dicen de nosotros',

    //footer
    we_have: 'Contamos con',
    current_clients: 'Clientes actuales',
    quality_guaranteed: 'Calidad garantizada',
    happy_workers: 'Trabajadores felices',
    Contact_information: 'Contáctanos para más información',
    name: 'Nombre',
    company: 'Empresa',
    What_represent: '¿Qué representas?',
    Manufactured_spare_parts: 'Fabrico repuestos para motos',
    motorcycle_parts: 'Vendo repuestos para motos',
    email: 'Correo electrónico',
    message: 'Mensaje',
    accept_conditions: 'Acepto las condiciones de',
    data_processing: 'Tratamientos de datos',
    Request_financing: 'Solicitar financiación',
    Send: 'Enviar',
    Where_meet: 'Dónde nos encontramos',
    dedicated_company: 'Empresa dedicada a la importación, comercialización y distribución de partes y accesorios para motocicletas.',
    links: 'Enlaces',
    Street: 'Km 1 via Galapa',
    contact2: 'Contacto:',
    polity: 'WIMPORTACIONES © All rights reserved',
    polity1: 'Designed by Maga Studios',
    polity2: 'Términos y condiciones',
    polity3: 'Política de privacidad',
    polity4: 'Política ambiental',
    polity5: 'Política de garantías ',
    polity6: 'Reglamento seguridad en el trabajo',
    //about
    About_us: 'Quiénes somos',
    Environmental_policy: 'Política ambiental',
    Warranty_policy: 'Política de garantías',
    Who_we_are: 'Quiénes somos',
    company_dedicated: 'Empresa dedicada a la importación, comercialización y distribución de partes y accesorios para motocicletas. Somos responsables de comercializar productos de calidad aportando políticas de transparencia para que el mercado tenga garantía de ellas; estamos comprometidos en minimizar el impacto que nuestra operación pueda causar al medio ambiente.',
    work_and_family: 'Aplicamos medidas encaminadas a conciliar el trabajo y la familia, ofrecer actividades de formación, mantener un trato cordial y respetuoso en todos los aspectos con nuestros empleados.',
    Mission: 'Misión',
    company_that_offers: 'Somos una empresa que ofrece repuestos y accesorios para motocicletas de calidad y a precios competitivos, apoyados en un grupo de profesionales con una amplia experiencia, brindando un excelente servicio de venta para generar la confianza y fidelidad de nuestros clientes.',
    Vision: 'Visión',
    With_new_strategies: 'Con nuevas estrategias buscamos ser reconocidos a nivel nacional como la mejor opción para nuestros clientes, garantizando repuestos de calidad y al mejor precio.',
    Our_work_team: 'Nuestro equipo de trabajo',

    Wimportaciones_is_committed: 'Wimportaciones está comprometida con el cuidado del medio ambiente y te invita a formar parte de esta campaña del reciclaje de baterías para moto usadas. Ayúdanos entregando las baterías para moto usadas de cualquier marca a nuestro personal autorizado del Almacén Wimpotaciones, quienes se encargaran de hacerla llegar a las empresas especializadas en el manejo de estos residuos.',
    pollute_the_planet: 'No contamines el planeta. ¡Recíclalas!',
    See_policies: 'Ver políticas',
    WIMPORTACIONES_store: 'Puedes hacer llegar tus baterías usadas al almacén WIMPORTACIONES en la ',
    WIMPORTACIONES_store2: 'Calle 44 #41-75 ',
    WIMPORTACIONES_store3: 'Barranquilla / Atlántico, Colombia o comunícate al celular:',
    distributor_of_any: 'Si eres distribuidor de cualquiera de nuestras líneas de producto, es importante que tengas en cuenta nuestra política de garantías. ',
    You_can_download: 'Puedes descargarlas a continuación:',
    See_guarantee_policy: 'Ver política de garantías',
    DATA_PROCESSING_POLICY: 'Política de tratamiento de datos',
    Start: 'Inicio ',

    //contact
    Company_dedicated_to_the_import: 'Empresa dedicada a la importación, comercialización y distribución de partes y accesorios para motocicletas.',

    //cataloge
    see_catalog_in_pdf: 'Ver catálogo en pdf',
    SEARCH_RESULTS: 'RESULTADOS DE BÚSQUEDA',
    generic_motorcycle_parts: 'Repuestos de motocicleta genéricos',
    rims_and_tires: 'Llantas y neumáticos',
    high_quality_motorcycle_parts: 'Repuestos de motocicleta de alta calidad.',

    Search_product: 'Buscar producto',
    All: 'Todo',
    Keys: 'Llaves',
    commands: 'Comandos',
    Chains: 'Cadenas',
    Pinions: 'Piñones',
    handlebars: 'Manillares',
    hands: 'Manecillas',
    presses: 'Prensas',

    GF: 'GF:',
    TAI: 'TAI:',
    GF_Premium: 'GF Premium:',
    generic_motorcycle_parts2: 'Repuestos de motocicleta genéricos /',
    wheels_and_tires: 'Llantas y neumáticos /',

    white: '',
    financing_policy: 'Política de financiación y garantías',

    Owner_of_Warehouse: 'Propietaria de Almacén y Taller de Motos David',
    WImportaciones_S_A_S: 'WImportaciones S.A.S, mercancía de calidad, con buenos precios para todas las empresas, excelente atención y sus envíos son fáciles y oportunos.',
    motorcycle_spare: 'Moto Repuesto El Ruby',
    better_prices: 'WImportaciones S.A.S, los mejores repuestos para motocicletas, con excelente calidad, mejores precios.',
    Warehouse_and_spare: 'Almacén y repuestos de motos BQ',
    Results: 'Resultados',
    Please_fill: '*Por favor rellena todos los campos requeridos en el formulario.',
    Please_accept: '*Por favor acepta las condiciones de tratamiento de datos.',
    Contact_us: 'Contáctanos',
    information_window: 'Ventana informativa',
    thank_you_so_much: '¡Muchas gracias!',
    Your_data_has_been: 'Tus datos han sido enviados correctamente. Pronto te estaremos contactando.',
    Understood: 'Entendido',
    product_photo: ' Foto de producto',
    schedule_call: 'Programar llamada',
    
    bearings: "Balineras",
    SPARK_PLUG: "Bujía",
    Throttle_cable: "Cable acelerador",
    Brake_cable: "Cable freno",
    Speedometer_cable: "Cable velocímetro",
    Clutch_cable: "Cable clutch",
    Chains: "Cadenas",
    Chain_link: "Cadenilla",
    Gearbox: "Caja de cambios",
    Bells: "Campanas",
    Spark_plug_cap: "Capuchón bujía",
    Carburetors: "Carburadores",
    Accelerator_pulleys: "Carretos de aceleración",
    Crankshafts: "Cigüeñales",
    Cylinders: "Cilindros",
    Controls: "Comandos",
    Turn_signals: "Direccionales",
    Clutch_disc: "Disco clutch",
    Output_sprocket_shaft: "Eje piñón salida",
    Shift_shafts: "Ejes de cambio",
    Headlights: "Farolas",
    Fuel_filter: "Filtro gasolina",
    Flasher: "Flasher",
    Chain_guard: "Guarda cadena",
    Crankshaft_kit:"Kit bielas",
    Timing_belt_kit: "Kit distribución",
    Saddle_mesh: "Malla sillín",
    Mufflers: "Mofles",
    Gear_lever: "Palanca de cambio",
    Pistons: "Pistones",
    Horn: "Pito",
    Sprocket_plate: "Plato piñón",
    Sprocket_holder: "Porta sprocker",
    Press: "Prensa",
    Regulators: "Reguladores",
    Ignition_switch: "Switch de encendido",
    Tank_cap: "Tapa tanque",
    Tires: "Neumáticos",
    Wheels: "Llantas",
    CDI: "CDI",
    Chain_tensor: "Tensor de cadenilla",
    Importaciones_is_quality_guarantee: "WImportaciones es calidad, garantía, excelencia, buen talento humano, eficiencia y eficacia. Los mejores en Colombia, sin duda.",
    Online_support: "Atención en línea",
    Message_here: "Mensaje aqui ",
    We_offer_our_customers_through: "Ofrecemos a nuestros clientes a través de su red de distribución una garantía que cubre todo defecto de fabricación o ensamble por un período de 3 meses.",
    We_offer_our_customers_direct_credits_with: "Ofrecemos a nuestros clientes créditos directos con la compañía, créditos a la medida de cada cliente; según el perfil de riesgos del cliente se define el plazo; aprobaciones en 24 horas.",
    Caribbean_Stock_Logistics_Center: "Centro Logistico Stock Caribe Ofibodega 21"
  },
  en: {
    //navigation
    home: 'Home',
    about: 'About us',
    contact: 'Contact us',
    Catalogue: 'Catalog',

    //home
    our_brands: 'Our brands',
    you_want: 'Do you want to sell',
    National_entrepreneurs: 'National entrepreneurs / Latam',
    our_products: 'our products',
    in_your_warehouse: 'in your store?',
    want_export: 'Do you want to export your products to',
    colombia_latan: 'Colombia and Latam?',
    our_catalog: 'Check our',
    product_catalog: 'Product catalog',
    asian_businessmen: 'asian businessmen',
    button_catalog: 'See catalog',
    Some_products: 'Some of our products',
    Spare_motorcycles: 'Spare parts for motorcycles',
    We_offer_you: 'We offer you',
    Guarantee: 'Warranty',
    Financing: 'Financing',
    Know_more: 'Learn more',
    say_us: 'What they say about us',

    //footer
    we_have: 'We have',
    current_clients: 'current clients',
    quality_guaranteed: 'Quality Guaranteed',
    happy_workers: 'happy workers',
    Contact_information: 'Contact us for more information',
    name: 'Name',
    company: 'Company',
    What_represent: 'What do you represent?',
    Manufactured_spare_parts: 'I manufacture spare parts for motorcycles',
    motorcycle_parts: 'I sell motorcycle parts',
    email: 'Your e-mail address',
    message: 'Message',
    accept_conditions: 'I accept the conditions of',
    data_processing: 'Data processing.',
    Request_financing: 'Apply for financing',
    Send: 'Send',
    Where_meet: 'Where to find us',
    dedicated_company: 'Company dedicated to the import, marketing and distribution of parts and accessories for motorcycles.',
    links: 'Links',
    Street: 'Km 1 via Galapa',
    contact2: 'Contact us:',
    polity: 'WIMPORTACIONES © All rights reserved',
    polity1: 'Designed by Maga Studios',
    polity2: 'Terms and Conditions',
    polity3: 'Privacy Policy',
    polity4: 'Environmental policy',
    polity5: 'Guarantee policy',
    polity6: 'Safety at work regulations',
    //about
    About_us: 'About us',
    Environmental_policy: 'Environmental policy',
    Warranty_policy: 'Warranty policy',
    Who_we_are: 'Who we are',
    company_dedicated: 'We are a company dedicated to the import, commercialization and distribution of motorcycle parts and accessories. We are responsible for marketing quality products providing transparency policies so that the market has a guarantee of them; we are committed to minimize the impact that our operation may cause to the environment.',
    work_and_family: 'We apply measures aimed at reconciling work and family, offering training activities, maintaining a cordial and respectful treatment in all aspects with our employees.',
    Mission: 'Mission',
    company_that_offers: 'We are a company that offers quality motorcycle parts and accessories at competitive prices, supported by a group of professionals with extensive experience, providing an excellent sales service to generate trust and loyalty of our customers.',
    Vision: 'Vision',
    With_new_strategies: 'With new strategies, we seek to be recognized nationally as the best option for our customers, ensuring quality parts at the best price.',
    Our_work_team: 'Our work team',

    Wimportaciones_is_committed: 'Wimportaciones is committed to caring for the environment and invites you to be part of this campaign to recycle used motorcycle batteries. Help us by delivering used motorcycle batteries of any brand to our authorized staff at the Wimpotaciones Warehouse, who will be in charge of sending them to companies specialized in handling this waste.',
    pollute_the_planet: "Don't pollute the planet. Recycle them!",
    See_policies: 'See policies',
    WIMPORTACIONES_store: 'You can send your used batteries to the WIMPORTACIONES store on',
    WIMPORTACIONES_store2: '44th Street #41-75',
    WIMPORTACIONES_store3: 'Barranquilla / Atlántico, Colombia or contact the cell phone: +57 3228463663',
    distributor_of_any: 'If you are a distributor of any of our product lines, it is important that you take into account our guarantee policy.',
    You_can_download: 'You can download them below:',
    See_guarantee_policy: 'See guarantee policy',
    DATA_PROCESSING_POLICY: 'Data processing policy',
    Start: 'Start',

    //contact
    Company_dedicated_to_the_import: 'Company dedicated to the import, commercialization and distribution of parts and accessories for motorcycles.',

    //cataloge
    see_catalog_in_pdf: 'See catalog in pdf',
    SEARCH_RESULTS: 'SEARCH RESULTS',
    generic_motorcycle_parts: 'Generic motorcycle parts',
    rims_and_tires: 'Rims and tires',
    high_quality_motorcycle_parts: 'High quality motorcycle parts.',

    Search_product: 'Search product',
    All: 'All',
    Keys: 'Llaves',
    commands: 'Commands',
    Chains: 'Chains',
    Pinions: 'Pinions',
    handlebars: 'Handlebars',
    hands: 'Hands',
    presses: 'Presses',
    GF: 'GF:',
    TAI: 'TAI:',
    GF_Premium: 'GF Premium:',
    generic_motorcycle_parts2: 'Generic motorcycle parts /',
    wheels_and_tires: 'Wheels and tires /',
    white: '',
    financing_policy: 'Financing policy and guarantees',

    Owner_of_Warehouse: 'Owner of Warehouse and Motorcycle Workshop David',
    WImportaciones_S_A_S: 'WImportaciones S.A.S, quality merchandise, with good prices for all companies, excellent service and their shipments are easy and timely.',
    motorcycle_spare: 'Motorcycle Spare The Ruby',
    better_prices: 'WImportaciones S.A.S, the best spare parts for motorcycles, with excellent quality, better prices.',
    Warehouse_and_spare: 'Warehouse and spare parts for BQ motorcycles',
    Results: 'Results',
    Please_fill: '*Please fill in all the required fields in the form.',
    Please_accept: '*Please accept the data processing conditions.',
    Contact_us: 'Contact us',
    information_window: 'Information window',
    thank_you_so_much: 'Thank you so much!',
    Your_data_has_been: 'Your data has been sent correctly. We will be contacting you soon.',
    Understood: 'Understood',
    product_photo: 'Product photo',
    schedule_call: 'Schedule call',
    
    bearings: "Bearings",
    SPARK_PLUG: "Spark plug",
    Throttle_cable: "Throttle cable",
    Brake_cable: "Brake cable",
    Speedometer_cable: "Speedometer cable",
    Clutch_cable: "Clutch cable",
    Chains: "Chains",
    Chain_link: "Chain link",
    Gearbox: "Gearbox",
    Bells: "Bells",
    Spark_plug_cap: "Spark plug cap",
    Carburetors: "Carburetors",
    Accelerator_pulleys: "Accelerator pulleys",
    Crankshafts: "Crankshafts",
    Cylinders: "Cylinders",
    Controls: "Controls",
    Turn_signals: "Turn signals",
    Clutch_disc: "Clutch disc",
    Output_sprocket_shaft: "Output sprocket shaft",
    Shift_shafts: "Shift shafts",
    Headlights: "Headlights",
    Fuel_filter: "Fuel filter",
    Flasher: "Flasher",
    Chain_guard: "Chain guard",
    Crankshaft_kit:"Crankshaft kit",
    Timing_belt_kit: "Timing belt kit",
    Saddle_mesh: "Saddle mesh",
    Mufflers: "Mufflers",
    Gear_lever: "Gear lever",
    Pistons: "Pistons",
    Horn: "Horn",
    Sprocket_plate: "Sprocket_plate",
    Sprocket_holder: "Sprocket_holder",
    Press: "Press",
    Regulators: "Regulators",
    Ignition_switch: "Ignition switch",
    Tank_cap: "Tank cap",
    Tires: "Tires",
    Wheels: "Wheels",
    CDI: "CDI",
    Chain_tensor: "Chain tensor",
    Importaciones_is_quality_guarantee: "W Importaciones is quality, guarantee, excellence, good human talent, efficiency, and effectiveness. The best in Colombia, without a doubt.",
    Online_support: "Online support",
    Message_here: "Message here.",
    We_offer_our_customers_through: "We offer our customers, through their distribution network, a warranty that covers any manufacturing or assembly defects for a period of 3 months.",
    We_offer_our_customers_direct_credits_with: "We offer our customers direct credits with the company, tailored to each customer's needs. The term of the credit is defined based on the customer's risk profile, and approvals are given within 24 hours.",
    Caribbean_Stock_Logistics_Center: "Caribbean Stock Logistics Center Ofibodega 21"
  }
}