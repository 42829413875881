import { createStore } from 'vuex'

export default createStore({
  state: {
    chats: [],
    chat: {
      id: "",
      typeUser: '',
      idConversation: "",
      date: "",
      message: "",
    },
  },
  mutations: {
    cargarchat(state, payload) {
      const chatsArray = payload
      state.chats = chatsArray
    },
    setchat(state, payload) {
      state.chats.push(payload)
    },
  },

  actions: {
    async loadChat({ commit }) {
      try {
        const res = await fetch(`https://wimportaciones-b7da1-default-rtdb.firebaseio.com/chat/${localStorage.getItem('token_chat')}.json`)
        const dataDB = await res.json()
        const arrayChats = []
        for (let id in dataDB) {
          arrayChats.push(dataDB[id])
        }
        commit('cargarchat', arrayChats)

      } catch (error) {
        console.log(error)
      }
    },

    async setDatoschats({ commit }, chat) {
      try {
        const res = await fetch(`https://wimportaciones-b7da1-default-rtdb.firebaseio.com/chat/${chat.idConversation}/${chat.id}.json`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(chat)
        })
        const dataDB = await res.json()
      } catch (error) {
        console.log(error)
      }
      commit('setchat', chat)
    },

  },
  modules: {
  }
})
